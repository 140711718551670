import {mapState, mapGetters, } from 'vuex'

import { formatPrice, getPrice } from '@/helpers/helpers.js';

export default {
  data: () => ({
    fixedCostsByDistance: [],
    fixedCostsByMonth: []
  }),
  created() {
    if (this.getPriceForm) {
      this.getFixedCostsByDistance();
      this.getFixedCostsByMonth();
    }
  },
  destroyed() {
    this.$set(this.$data, 'fixedCostsByMonth', [])
  },
  computed: {
    ...mapState('reseller', {
      firstStepStyle: (state) => state.customStyle.firstStep,
      currencySymbol: state => state.resellerInfo?.market?.currency?.symbol || 'kr',
    }),
    ...mapState('product', [
      'vehicle',
      'priceForm',
      'vehicleColor',
      'selectedDistance',
      'downPaymentsByDistance'
    ]),
    ...mapState('order', [
      'accessories',
      'vehicleCostId',
      'totalMonthlyPrice'
    ]),
    ...mapGetters('product', [
      'getDefaults',
      'getPriceForm',
    ]),
    ...mapGetters('financing', ['totalMonthlyCost', 'monthlyCostOfAccessories']),
    ...mapGetters('filter', ['getFinanceFormName']),
    isHasDefault() {
      return Object.keys(this.getDefaults).length
    },
    distance() {
      return this.getDefaults[this.getPriceForm]['distance'];
    },
  },
  methods: {
    formatPrice,
    getFixedCostsByDistance() {
      const fixedCostsByDistance = this.vehicle.costs ?
        this.vehicle.costs.data
          .filter(item => item.finance_form === this.getPriceForm)
          .filter(item => {
            item['general_price'] = getPrice(item);

            if (typeof this.getDefaults[this.getPriceForm] === 'undefined') return;

            return item.distance === this.getDefaults[this.getPriceForm].distance
          })
          .sort((a, b) => a.months - b.months)
          .sort((a, b) => getPrice(a) - getPrice(b)) : []

      this.$set(this.$data, 'fixedCostsByDistance', fixedCostsByDistance);
    },
    getFixedCostsByMonth() {
      const fixedCostsByMonth = this.vehicle.costs ?
        this.vehicle.costs.data
          .filter(item => item.finance_form === this.getPriceForm)
          .filter(item => {
            item['general_price'] = getPrice(item);

            if (typeof this.getDefaults[this.getPriceForm] === 'undefined') return;

            return item.months === this.getDefaults[this.getPriceForm].months
          })
          .sort((a, b) => getPrice(a) - getPrice(b)) : []

      this.$set(this.$data, 'fixedCostsByMonth', fixedCostsByMonth);

      const currentFixedCost = fixedCostsByMonth.filter(fc => fc.distance === this.getDefaults[this.getPriceForm].distance)

      if (currentFixedCost[0]) {
        this.setDownPayments(currentFixedCost[0])
      }
    },


    getFormCostPrice() {
      if (!this.isHasDefault) return 0;

      const price = this.totalMonthlyCost;

      return isNaN(price) ? 0 : formatPrice(price)
    },
    getColorAndAccessoryVat(form) {
      if (!this.isHasDefault) return 0

      const activeColor = this.vehicleColor ? this.vehicleColor.id : ''

      if (!this.getDefaults[form]) return

      const activeCostId = this.getDefaults[form].id
      const formVehicleColors = this.vehicle.costs.data.find(
        (item) => item.id === activeCostId
      )
      const formVehicleColorPrice = formVehicleColors.vehicleColors.data.find(
        (item) => item.id === activeColor
      )

      const vehicleColorPrice = formVehicleColorPrice
        ? formVehicleColorPrice.price.vat
        : 0

      const accessoriesMonthlyPrice =
        this.finance_form_name === form ? this.totalAccessoriesVatPrice : 0

      return vehicleColorPrice + accessoriesMonthlyPrice
    },
    setDownPayments(fixedCost) {
      if (fixedCost) {
        this.$store.dispatch('product/setSelectedDistance', {down_payment: fixedCost.down_payment, monthly_cost: fixedCost.monthly_cost})
        this.$store.dispatch('product/setCurrentMonthlyPrice', fixedCost.monthly_cost)

        this.$store.dispatch('order/saveAllFields', {'totalMonthlyPrice': this.calculatePrice})

        const defaultPayment = {
          id: null,
          value: this.selectedDistance.down_payment,
          monthly_cost: this.selectedDistance.monthly_cost
        }

        const sortedDownPayments = [defaultPayment,...fixedCost.downPayments.data].sort((a, b) => {
          return b.value > a.value ? -1 : 1
        })

        this.$store.dispatch('product/setDownPaymentsByDistance', sortedDownPayments)
      }
    }
  },
  watch: {
    priceForm: function (n) {
      if (n) {
        this.getFixedCostsByDistance();
        this.getFixedCostsByMonth();
      }
    },
    vehicle: function(n) {
      if (n && !this.fixedCostsByMonth.lenght) {
        this.getFixedCostsByDistance();
        this.getFixedCostsByMonth();
      }
    },
    getDefaults: {
      handler: function(newVal, oldVal) {
        if(oldVal[this.getPriceForm]) {
          if (newVal[this.getPriceForm].months !== oldVal[this.getPriceForm].months) {
            this.getFixedCostsByMonth();
          }

          if (newVal[this.getPriceForm].distance !== oldVal[this.getPriceForm].distance) {
            this.getFixedCostsByDistance();
          }
        }
      },
      deep: true,
    }
  }
}
