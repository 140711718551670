<template lang="pug">
div
  .costs-list-title(
     @click="setActive('Corporate leasing')"
  )
    span
      i.fas.fa-chevron-right.costs-list-chevron
      span.costs-list-title-text {{ $t("financeForms.corporateLeasing") }}
    span.costs-list-price
     span.big {{ costPrice }}
     | &nbsp;&nbsp;{{ currencySymbol }}/{{ $t('mon') }}

  .costs-list-content

    .costs-list-prices
      div
        .costs-list-subtitle.m-t-20
          strong {{ firstStepStyle.total_price_title }} {{ !getIsVatIncluded ? `(${$t('exmoms')})` : '' }}
        .m-t-15.big
         | {{ formatPrice(totalPrice) }} {{ currencySymbol }}
      div
        .costs-list-subtitle.m-t-20
          strong {{ firstStepStyle.credit_amount_title }}
        .m-t-15.big {{ formatPrice(totalPrice - calculatedCashPaymentPrice) }} {{ currencySymbol }}
      div
        .costs-list-subtitle.m-t-20
          strong {{ firstStepStyle.interest_title }}
        .m-t-15.big {{ rate }} %

    .costs-list-subtitle.m-t-50
      strong {{ firstStepStyle.residual_title }}, {{ currencySymbol }} {{ !getIsVatIncluded ? $t('exmoms') : '' }}:
    .row.m-t-40
      .costs-list-slider.col-md-12
        vue-slider(
          :min="minResidual"
          :max="maxResidual"
          v-model="residual"
          :dotSize="slider.dotSize"
          :height="slider.height"
          :silent="true"
          :contained="true"
          :drag-on-click="true"
          :tooltip="'always'"
          @drag-end="sliderChange($event)"
          :tooltip-formatter="formatter1"
        )

    .costs-list-subtitle.m-t-40
      strong {{ firstStepStyle.cash_payment_title }}, {{ currencySymbol }} {{ !getIsVatIncluded ? $t('exmoms') : '' }}:
    .row.m-t-40
      .costs-list-slider.col-md-12
        vue-slider(
          :min="minCashPayment"
          :max="maxCashPayment"
          v-model="cashPayment"
          :dotSize="slider.dotSize"
          :height="slider.height"
          :silent="true"
          :contained="true"
          :drag-on-click="true"
          :tooltip="'always'"
          @drag-end="sliderChange($event)"
          :tooltip-formatter="formatter2"
        )

    MonthChoiser(
      :fixedCostsBy="fixedCostsByDistance"
      :showPices="false"
    )
</template>

<script>

import CostsMixin from '@/mixins/CostsMixin'
import CostsSliderMixin from '@/mixins/CostsSliderMixin'

export default {
  mixins: [
    CostsMixin,
    CostsSliderMixin,
  ],
  components: {
    MonthChoiser: () => import('./../costs-parts/month'),
  },
  computed: {
    formatter1() {
      return (
        this.formatPrice(this.calculatedResidualPrice) +
        ' kr (' +
        this.residual +
        '%)'
      )
    },
    formatter2() {
      return (
        this.formatPrice(this.calculatedCashPaymentPrice) +
        ' kr (' +
        this.cashPayment +
        '%)'
      )
    },
    costPrice() {
      return this.getFormCostPrice('Corporate leasing')
    },
  },
}
</script>